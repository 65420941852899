import React from 'react'
import YouTubePlayer from 'react-player/lib/players/YouTube'
import { Card, Image } from 'semantic-ui-react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import Layout from '../components/Layout'
import SEO from '../components/SEO'

import ContegoMeaning from '../images/logos/contego-meaning.png'
import Magazine from '../images/general/magazine.png'
import PatentOne from '../images/patents/patent-1.png'
import PatentTwo from '../images/patents/patent-2.png'
import PatentThree from '../images/patents/patent-3.png'

const AboutPage = () => {
  return (
    <Layout>
      <SEO
        title="About"
        description="At Contego Spa Designs Inc., our mission is to transform the pedicure industry with our patented pedicure spa technologies. In addition to educating salon owners, we also want the everyday consumer to know about pedicure safety. We are confident in the quality of our products and strive to give you the best customer service. Every Contego product is designed with unique user-friendly features while performing at the highest sanitation standards."
        meta={[
          {
            name: 'keywords',
            content:
              'Nail salon design,Nail salon startup cost,Pedicure spa chair,Contego,Contego spa,Contego spa designs,Contego spa designs inc,Contego spa liner,Contego spa chair review,Contego spa reviews,Contego spa pedicure,Contego spa pedicure chair,Contego pedicure chairs price,Pedicure chair,Pedicure spa,Spa chair,Design ideas for nail salon,Luxury pedicure chairs,Cheap pedicure chairs,Pedicure spa chairs for sale,Cheap spa pedicure chairs,pedicure spa chair package deal,Contego pedicure chairs,Pedicure chairs,Pedicure chairs for sale,Pedicure chairs with massage,Pedicure chairs cheap,Pedicure chairs parts,Pedicure chairs wholesale,Pedicure chair ideas,Pedicure chair for sale,Spa pedicure chairs,Nail salon pedicure chair,Nail Salon Furniture,Nail Salon Equipment,Pedi spa chair,Spa pedicure chairs,Salon Equipment,Pedicure chair parts,Pedicure furniture'
          }
        ]}
      />
      <div className="container-fluid bg-light">
        <div className="container-fluid">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-md-7 py-4">
              <div className="player-wrapper">
                <YouTubePlayer
                  className="react-player"
                  url="https://www.youtube.com/watch?v=UgweYkPh1jU"
                  playing
                  controls={false}
                  width="100%"
                  height="100%"
                  // Other ReactPlayer props will work here
                />
              </div>
            </div>
            <div className="col-md-1" />
            <div className="col-md-4 py-4 animated fadeInRight slow">
              <img src={ContegoMeaning} alt="Contego means to protect" />
              <p className="mt-4 pr-4">
                At Contégo Spa Designs Inc., our mission is to revolutionize the pedicure industry
                with our patented pedicure spa technology. In addition to educating salon owners, we
                also want the everyday consumer to know about pedicure safety. We are confident in
                the quality of our products and strive to give you the best customer service. Every
                Contégo product is designed with unique user-friendly features, while performing at
                the highest sanitation standards.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container py-4" id="safety-section">
        <div className="row py-4">
          <div className="col-xl-5 py-4 d-flex flex-column justify-content-center align-items-center pr-5">
            <h1 className="w-100">SAFER FASTER CLEANER</h1>
            <p className="pr-4">
              In 2015, the California Board of Barbering and Cosmetology announced new legislation
              that California nail techs using disposable pedicure liners can skip the 10-minute
              disinfecting soak of their pedicure basin between clients. Enter Contégo Spa Designs
              Inc., Pedi-Spa Chair. This unique chair was designed with patented technology that
              includes a disposable foot spa system called Disposable Contégo Jet-Liner™. The liner
              protects each and every client from possible cross-contamination. Both the liner and
              the jet are disposed of after the service is complete, and every new service begins
              with a new Disposable Contégo Jet-Liner™. Your pedicure clients no longer have to give
              up the luxurious water massage they receive from the traditional foot spa jets because
              of concerns over safety and hygiene. www.nailsmag.com
            </p>
          </div>
          <div className="col-xl-7 py-4">
            <img src={Magazine} className="img-fluid" alt="magazine cover" />
          </div>
        </div>
        <div className="row py-4">
          <div className="col-sm-4">
            <h1>IN OUR CUSTOMER SALONS</h1>
            <p className="mb-4">
              <strong>NEW</strong> Technology <strong>DISPOSABLE JET-LINER™</strong>
            </p>
            <ul className="benefits">
              <li>
                <p className="ml-4"> 100% Disposed After Each Use</p>
              </li>
              <li>
                <p className="ml-4"> No Cross Contamination</p>
              </li>
              <li>
                <p className="ml-4"> Germs are Being Completely Disposed</p>
              </li>
            </ul>
          </div>
          <div className="col-sm-8">
            <div className="player-wrapper">
              <YouTubePlayer
                className="react-player"
                url="https://www.youtube.com/watch?v=CIhTNawXC7U"
                controls={false}
                width="100%"
                height="100%"
                // Other ReactPlayer props will work here
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-light shadow py-4">
        <div className="row py-4">
          <h1 className="w-100 text-center">OUR PATENTED TECHNOLOGIES</h1>
          <p className="w-100 text-center px-5">THE WORLD'S ONLY DISPOSABLE JET-LINER™</p>
        </div>
        <div className="row d-flex py-4">
          <div className="col-md-4 py-4 pr-4 d-flex flex-column">
            <Card fluid>
              <Image src={PatentOne} fluid />
              <Card.Content>
                <Card.Header>
                  METHOD & APPARATUS FOR DISPOSABLE SPA CHAIR PEDICURE BASIN SANITATION
                </Card.Header>
                <Card.Meta>
                  <p>PATENT NO.: US 7,950,079 B2</p>
                </Card.Meta>
              </Card.Content>
              <Card.Content extra>
                <AniLink
                  paintDrip
                  duration={1.62}
                  hex="#ffffff"
                  className="action-button px-4 text-center py-2 rounded-pill mt-4"
                  to="/ip"
                >
                  LEARN MORE
                </AniLink>
              </Card.Content>
            </Card>
          </div>
          <div className="col-md-4 py-4 pr-4 d-flex flex-column">
            <Card fluid>
              <Image src={PatentTwo} fluid />
              <Card.Content>
                <Card.Header>PEDICURE BASIN W/ OVERFLOW PROTECTION</Card.Header>
                <Card.Meta>
                  <p>PATENT NO.: US 9,289,353 B2</p>
                </Card.Meta>
              </Card.Content>
              <Card.Content extra>
                <AniLink
                  paintDrip
                  duration={1.62}
                  hex="#ffffff"
                  className="action-button px-4 text-center py-2 rounded-pill mt-4"
                  to="/ip"
                >
                  LEARN MORE
                </AniLink>
              </Card.Content>
            </Card>
          </div>
          <div className="col-md-4 py-4 pr-4 d-flex flex-column">
            <Card fluid>
              <Image src={PatentThree} fluid />
              <Card.Content>
                <Card.Header>LIQUID CONTAINER WITH REPLACEABLE LINER</Card.Header>
                <Card.Meta>
                  <p>PATENT NO.: US 9,439,831 B2</p>
                </Card.Meta>
              </Card.Content>
              <Card.Content extra>
                <AniLink
                  paintDrip
                  duration={1.62}
                  hex="#ffffff"
                  className="action-button px-4 text-center py-2 rounded-pill mt-4"
                  to="/ip"
                >
                  LEARN MORE
                </AniLink>
              </Card.Content>
            </Card>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AboutPage
